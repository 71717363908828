import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Redirect } from 'react-router-dom';
import { Page, Alert } from 'components';
import { Header, Form, Results } from './components';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutAuthor: {
    marginTop: theme.spacing(3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  projectCover: {
    marginTop: theme.spacing(3)
  },
  projectDetails: {
    marginTop: theme.spacing(3)
  },
  preferences: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  header: {
    marginBottom: '20px'
  },
  autButton: {
    marginTop: '5px',
    borderColor: '#2D462D',
    color: '#679D64',
    marginLeft: '10px',
    background: 'transparent'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: 'auto',
    marginRight: '30px',
    background: 'transparent',
    marginTop: '5px'
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px'
  }
}));

const UsersDetail = (props) => {
  const classes = useStyles();

  const [valueForm, setValueForm] = useState(false)
  const [valueFormSenhas, setValueFormSenhas] = useState(false)

  const data = JSON.parse(localStorage.getItem('userData'))

  const userId = data._id

  const { permission } = JSON.parse(localStorage.getItem('userData'))

  const [user, setUser] = useState({
    equipments: [],
    _id: '',
    name: '',
    username: '',
    email: '',
    birth: '',
    password: '',
    master: '',

    permissionSGU: {
      home: {
        read: '',
        write: '',
      },
      equipments: {
        read: '',
        write: '',
      },
      units: {
        read: '',
        write: '',
      },
      restricted: {
        read: '',
        write: '',
      },
      report: {
        read: '',
        write: '',
      },
      settings: {
        read: '',
        write: '',
      },
      companyData: {
        read: '',
        write: ''
      },
    },

    permission: {
      home: {
        read: '',
        write: ''
      },
      users: {
        read: '',
        write: ''
      },
      units: {
        read: '',
        write: ''
      },
      cooperatives: {
        read: '',
        write: ''
      },
      equipments: {
        read: '',
        write: ''
      },
      maps: {
        read: '',
        write: ''
      },
      charts: {
        read: '',
        write: ''
      },
      support: {
        read: '',
        write: ''
      },
      equipmentsQL: {
        read: '',
        write: ''
      },
      notifications: {
        read: '',
        write: ''
      },
      userspassword: {
        read: '',
        write: ''
      },
      general: {
        read: '',
        write: ''
      }
    },
    fone: '',
    company: '',
    cpfcnpj: '',
    validationHash: '',
    createdAt: '',
    updatedAt: '',
    __v: 0,
    token: '',
    firebaseToken: '',
    validationEmail: Boolean,
    oldName: null,
    blocked: null
  });

  const [alertMessage, setAlertMessage] = useState({ "message": '', "type": '' });

  const [open, setOpen] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const [openAuthenticate, setOpenAuthenticate] = useState(false);
  const [openAuthenticateApi, setOpenAuthenticateApi] = useState(false);
  const [openAuthPassword, setOpenAuthPassword] = useState(false);
  const [openBlockUser, setOpenBlockUser] = useState(false);

  const [redirect, setRedirect] = useState(false);
  const [progress, setShowProgress] = useState(false);

  const [activactions, setActivactions] = useState([]);
  const [pass, setPass] = useState([]);

  const removeUser = () => {
    (async () => {
      setOpen(false);
      axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .delete(`/users/${user._id}`, { "token": data.token })
        .then(() => {
          setRedirect(true);
        });
    })();
  };

  const authUser = () => {
    (async () => {
      setOpenAuth(false);
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
        '/validateemailforced', { _id: user._id, "token": data.token });
    })();
  };

  const authenticateUser = () => {
    setOpenAuthenticate(false);
    axios(process.env.REACT_APP_API_MOTOMCO, getToken())
      .post('/authenticateuser', { "id": user._id, "token": data.token })
      .then((response) => {
        alert('Usuário autenticado');
      })
      .catch((error) => {
        alert('Erro', error.message);
      });
  };

  const unblockFone = () => {
    axios(process.env.REACT_APP_API_MOTOMCO, getToken())
      .post('/user/clearexclusiveaccess', { "email": user.email, "token": data.token })
      .then((response) => {
        alert('Telefone desbloqueado');
      })
      .catch((error) => {
        alert('Erro', error.message);
      });
  };

  const authenticateUserApi = () => {
    setOpenAuthenticateApi(false);
    setShowProgress(true);
    axios(process.env.REACT_APP_API_MOTOMCO, getToken())
      .post('/authenticateuserapi', { "cpf": user.cpfcnpj, "token": data.token })
      .then((response) => {
        setShowProgress(false);
        let name = response.data.name;
        let oldName = user.name;
        let birth = response.data.birth;
        setUser((values) => ({
          ...values,
          ['name']: name,
          ['oldName']: oldName,
          ['birth']: birth
        }));
      })
      .catch((error) => {
        setShowProgress(false);
        alert('Erro', error.message);
      });
  };


  const authUserPassword = () => {
    (async () => {
      setOpenAuthPassword(false);
      try {
        var resp = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post(
          '/passwordgeneratepermission',
          {
            email: data.email,
            password: data.password,
            id: user._id,
            token: data.token
          }
        )
        alert(resp.data.msg);
      } catch (error) {
        alert("Erro ao atualizar.")
      }
    })();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAuth = () => {
    setOpenAuth(false);
  };

  const handleCloseAuthPassword = () => {
    setOpenAuthPassword(false);
  };

  const getActivations = async (email) => {
    try {
      const response = await axios(
        process.env.REACT_APP_API_GERA_SENHA,
        getToken()
      ).post('/log/unlockbyuser', { "username": email })
      setActivactions(response.data)
    } catch (error) {
      console.log(error);
    }
  };

  const getPass = async (email) => {
    try {
      const responses = await axios(
        process.env.REACT_APP_API_GERA_SENHA,
        getToken()
      ).post('/log/passuser', { "username": email, "params": 50 })
      setPass(responses.data)
    } catch (error) {
      console.log(error);
      alert('Erro', error.message);
    }
  };

  useEffect(() => {
    (async () => {
      const { id } = props.match.params;

      try {
        const user = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get(`/users/${id}`);
        var permission = false;
        if (user && user.data && user.data.master) {
          permission = user.data.master;
        }

        if (!user.data.permissionSGU || !user.data.permissionSGU.home || !user.data.permissionSGU.companyData) {
          user.data.permissionSGU = {
            home: {
              read: permission,
              write: permission
            },
            equipments: {
              read: permission,
              write: permission
            },
            units: {
              read: permission,
              write: permission
            },
            restricted: {
              read: permission,
              write: permission
            },
            cooperatives: {
              read: permission,
              write: permission
            },
            report: {
              read: permission,
              write: permission
            },
            settings: {
              read: permission,
              write: permission
            },
            companyData: {
              read: permission,
              write: permission
            },
          }
        }

        if (!user.data.permission || !user.data.permission.home || !user.data.permission.cooperatives) {
          user.data.permission = {
            home: {
              read: permission,
              write: permission
            },
            users: {
              read: permission,
              write: permission
            },
            units: {
              read: permission,
              write: permission
            },
            cooperatives: {
              read: permission,
              write: permission
            },
            equipments: {
              read: permission,
              write: permission
            },
            maps: {
              read: permission,
              write: permission
            },
            charts: {
              read: permission,
              write: permission
            },
            support: {
              read: permission,
              write: permission
            },
            equipmentsQL: {
              read: permission,
              write: permission
            },
            notifications: {
              read: permission,
              write: permission
            },
            userspassword: {
              read: permission,
              write: permission
            },
            general: {
              read: permission,
              write: permission
            }
          }
        }

        setUser(user.data);
        getActivations(user.data.email);
        getPass(user.data.email);
      } catch (error) {
        console.log(error);
        alert(error.message)
      }
    })();
  }, []);

  const fieldChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: value
    }))
  }

  const cpfMask = (value) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const phoneMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1)$2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  };

  const cpfChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: cpfMask(value)
    }));
  };

  const phoneChanged = (event, field, value) => {
    event.persist && event.persist();
    setUser((values) => ({
      ...values,
      [field]: phoneMask(value)
    }));
  };

  const saveUser = () => {
    (async () => {
      user.userId = userId
      axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .put('/user', user)
        .then(() => {
          setAlertMessage({ "message": 'Atualizado com sucesso.!', "type": 'success' });
        })
        .catch((e) => {
          if (String(e).includes("400")) {
            setAlertMessage({ "message": 'O CPF já existe!', "type": 'error' });
          } else {
            setAlertMessage({ "message": 'Falha ao carregar!', "type": 'error' });
          }
        });
    })();
  };

  const forgotPassword = () => {
    (async () => {
      axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .post('/forgotpassword', { "email": user.email, "token": data.token })
        .then(() => {
          alert('Enviado com sucesso');
        })
        .catch(() => {
          alert('Erro ao enviar');
        });
    })();
  };

  const handleBlockUser = () => {
    setOpenBlockUser(false);
    (async () => {
      axios(process.env.REACT_APP_API_MOTOMCO, getToken())
        .post('/blockuser', { id: user._id, block: !user.blocked, "token": data.token })
        .then(() => {
          alert('Atualizado com sucesso');
          setUser((values) => ({
            ...values,
            ['blocked']: !user.blocked
          }));
        })
        .catch((e) => {
          alert('Erro ao atualizar');
        });
    })();
  };

  //Abrir ultimas ativações
  return (
    <Page className={classes.root} title="Usuários">
      {redirect && <Redirect to="/users" />}
      <Header className={classes.header} />
      <Divider className={classes.divider} />

      {alertMessage.message && (
        <Alert
          className={classes.alert}
          message={alertMessage.message}
          variant={alertMessage.type}
        />
      )}

      <div style={{ display: 'flex' }}>
        <Button
          className={classes.autButton}
          onClick={() => {
            setOpenAuthenticate(true);
          }}
          size="small"
          disabled={
            permission.users.write === true ? false : true
          }
          variant="outlined">
          Autenticar usuário
        </Button>
        <Button
          className={classes.autButton}
          onClick={() => {
            setOpenAuth(true);
          }}
          size="small"
          disabled={
            permission.users.write === true ? false : true
          }
          variant="outlined">
          Validar E-mail
        </Button>

        <Button
          className={classes.autButton}
          onClick={forgotPassword}
          size="small"
          disabled={
            permission.users.write === true ? false : true
          }
          variant="outlined">
          Enviar email recuperação de senha
        </Button>

        <Button
          size="small"
          className={classes.autButton}
          disabled={
            permission.users.write === true ? false : true
          }
          onClick={() => setOpenAuthPassword(true)}
          variant="outlined">
          Autorizar Gera Senha
        </Button>

        <Button
          size="small"
          className={classes.autButton}
          disabled={
            permission.users.write === true ? false : true
          }
          onClick={unblockFone}
          variant="outlined">
          Desbloquear telefone
        </Button>

        <Button
          className={classes.rmvButton}
          disabled={
            permission.users.write === true ? false : true
          }
          onClick={() => setOpen(true)}
          size="small"
          variant="outlined">
          Remover
        </Button>
      </div>

      {progress === false && (
        <Button
          style={{ marginTop: '10px' }}
          className={classes.autButton}
          onClick={() => {
            setOpenAuthenticateApi(true);
          }}
          size="small"
          disabled={
            permission.users.write === true ? false : true
          }
          variant="outlined">
          Validar dados via API
        </Button>
      )}

      <Button
        style={{ marginTop: '10px' }}
        className={classes.autButton}
        onClick={() => {
          setOpenBlockUser(true);
        }}
        size="small"
        disabled={permission.users.write === true ? false : true}
        variant="outlined">
        {user.blocked ? 'Desbloquear Usuário' : 'Bloquear Usuário'}
      </Button>

      <Divider className={classes.divider} />

      {progress && <LinearProgress />}

      <Form
        alertMessage={alertMessage}
        className={classes.aboutProject}
        fieldChanged={fieldChanged}
        cpfChanged={cpfChanged}
        phoneChanged={phoneChanged}
        saveUser={saveUser}
        permission={permission}
        user={user}
        setUser={setUser}
        setValueForm={setValueForm}
        setValueFormSenhas={setValueFormSenhas}
      />
      {!valueForm ? null :
        <Results title={'Ativações'} data={activactions} />
      }
      {!valueFormSenhas ? null :
        <Results title={'Senhas Geradas'} data={pass} />
      }
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">{'Remover'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente remover esse usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={removeUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseAuth}
        open={openAuth}>
        <DialogTitle id="alert-dialog-title">{'Autorizar'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja autorizar o e-mail desse usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseAuth}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpenAuthenticate(false)}
        open={openAuthenticate}>
        <DialogTitle id="alert-dialog-title">
          {'Autenticar Cadastro'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja validar o cadatro deste usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenAuthenticate(false)}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authenticateUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => setOpenAuthenticateApi(false)}
        open={openAuthenticateApi}>
        <DialogTitle id="alert-dialog-title">{'Validar dados'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja validar os dados deste usuário via api externa de consulta ao CPF?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenAuthenticateApi(false)}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authenticateUserApi}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseAuthPassword}
        open={openAuthPassword}>
        <DialogTitle id="alert-dialog-title">
          {'Autorizar Gera Senha'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja autorizar este usuário a gerar senhas para acesso ao
            equipamento Motomco?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleCloseAuthPassword}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={authUserPassword}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={() => {
          setOpenBlockUser(false);
        }}
        open={openBlockUser}>
        <DialogTitle id="alert-dialog-title">
          {user.blocked ? 'Desbloquear Usuário' : 'Bloquear Usuário'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja {user.blocked ? 'desbloquear' : 'bloquear'} este usuário para
            gerar ativações para o equipamento Motomco?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setOpenBlockUser(false);
            }}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={handleBlockUser}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default UsersDetail;
