import React from 'react';

import clsx from 'clsx';
import dateformat from 'utils/dateformat';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Divider,
  Button,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  textField: {
    margin: theme.spacing(1)
  }
}));

const Form = (props) => {
  const classes = useStyles()
  const {
    user,
    permission,
    fieldChanged,
    cpfChanged,
    phoneChanged,
    saveUser,
    setUser,
    setValueForm,
    setValueFormSenhas
  } = props;

  const masterUser = JSON.parse(localStorage.getItem('userData'));

  return (
    <Card className={clsx(classes.root)}>
      <CardContent>
        <form>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  fullWidth
                  label="Nome"
                  name="nome"
                  value={user.name}
                  variant="outlined"
                  onChange={(event) =>
                    fieldChanged(event, 'name', event.target.value)
                  }
                  disabled={!masterUser.permission.users.write}
                />
                {user.oldName && (
                  <s style={{ marginLeft: '20px' }}>{user.oldName}</s>
                )}
                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  fullWidth
                  label="Email"
                  name="email"
                  value={user.email}
                  variant="outlined"
                  disabled={!masterUser.permission.users.write}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="CPF"
                  name="cpf"
                  value={user.cpfcnpj}
                  variant="outlined"
                  onChange={(event) =>
                    cpfChanged(event, 'cpfcnpj', event.target.value)
                  }
                  disabled={!masterUser.permission.users.write}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="Fone"
                  name="fone"
                  value={user.fone}
                  variant="outlined"
                  onChange={(event) =>
                    phoneChanged(event, 'fone', event.target.value)
                  }
                  disabled={!masterUser.permission.users.write}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  fullWidth
                  label="Empresa"
                  name="company"
                  value={user.company}
                  variant="outlined"
                  onChange={(event) =>
                    fieldChanged(event, 'company', event.target.value)
                  }
                  disabled={!masterUser.permission.users.write}
                />

                <TextField
                  className={classes.textField}
                  autoComplete="off"
                  label="Cadastrado em"
                  name="createdAt"
                  value={dateformat(user.createdAt, true)}
                  variant="outlined"
                  disabled={!masterUser.permission.users.write}
                />

                <TextField
                  className={classes.textField}
                  aria-readonly="true"
                  label="Último login"
                  name="updatedAt"
                  value={dateformat(user.updatedAt, true)}
                  variant="outlined"
                  disabled={!masterUser.permission.users.write}
                />

                {!masterUser.master ? null :
                  <>
                    <div>
                      <br />
                      <h4 style={{ "marginBottom": '0px', "marginTop": "20px", "marginLeft": "10px" }}>Autorizações Site SGU</h4>
                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}></h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            onChange={(event) => {
                              user.permissionSGU.home.read = event.target.checked;
                              user.permissionSGU.equipments.read = event.target.checked;
                              user.permissionSGU.units.read = event.target.checked;
                              user.permissionSGU.restricted.read = event.target.checked;
                              user.permissionSGU.report.read = event.target.checked;
                              user.permissionSGU.settings.read = event.target.checked;
                              user.permissionSGU.companyData.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver Todos " />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            onChange={(event) => {
                              user.permissionSGU.home.read = event.target.checked;
                              user.permissionSGU.equipments.read = event.target.checked;
                              user.permissionSGU.units.read = event.target.checked;
                              user.permissionSGU.restricted.read = event.target.checked;
                              user.permissionSGU.report.read = event.target.checked;
                              user.permissionSGU.settings.read = event.target.checked;
                              user.permissionSGU.companyData.read = event.target.checked;
                              user.permissionSGU.home.write = event.target.checked;
                              user.permissionSGU.equipments.write = event.target.checked;
                              user.permissionSGU.units.write = event.target.checked;
                              user.permissionSGU.restricted.write = event.target.checked;
                              user.permissionSGU.report.write = event.target.checked;
                              user.permissionSGU.settings.write = event.target.checked;
                              user.permissionSGU.companyData.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar Todos " />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Home</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.home.read}
                            onChange={(event) => {
                              user.permissionSGU.home.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.home.write}
                            onChange={(event) => {
                              user.permissionSGU.home.read = event.target.checked;
                              user.permissionSGU.home.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Unidades</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.units.read}
                            onChange={(event) => {
                              user.permissionSGU.units.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.units.write}
                            onChange={(event) => {
                              user.permissionSGU.units.read = event.target.checked;
                              user.permissionSGU.units.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>


                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Equipamentos</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.equipments.read}
                            onChange={(event) => {
                              user.permissionSGU.equipments.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.equipments.write}
                            onChange={(event) => {
                              user.permissionSGU.equipments.read = event.target.checked;
                              user.permissionSGU.equipments.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Restrito</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.restricted.read}
                            onChange={(event) => {
                              user.permissionSGU.restricted.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.restricted.write}
                            onChange={(event) => {
                              user.permissionSGU.restricted.read = event.target.checked;
                              user.permissionSGU.restricted.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Relatório</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.report.read}
                            onChange={(event) => {
                              user.permissionSGU.report.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.report.write}
                            onChange={(event) => {
                              user.permissionSGU.report.read = event.target.checked;
                              user.permissionSGU.report.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Configurações</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.settings.read}
                            onChange={(event) => {
                              user.permissionSGU.settings.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.settings.write}
                            onChange={(event) => {
                              user.permissionSGU.settings.read = event.target.checked;
                              user.permissionSGU.settings.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Dados da Empresa</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.companyData.read}
                            onChange={(event) => {
                              user.permissionSGU.companyData.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permissionSGU.companyData.write}
                            onChange={(event) => {
                              user.permissionSGU.companyData.read = event.target.checked;
                              user.permissionSGU.companyData.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>
                    </div>

                    <br />

                    <div>
                      <h4 style={{ "marginBottom": '0px', "marginTop": "20px", "marginLeft": "10px" }}>Autorizações Site API</h4>
                      <div className="col-sm-12 mt-0" style={{ marginLeft: "10px" }}>

                        <h6
                          style={{
                            marginTop: "15px",
                            marginBottom: "0px",
                            borderBottom: "solid 1px #80808061",
                            paddingBottom: "5px",
                          }}
                        >
                          Master
                        </h6>
                        <FormControlLabel
                          className="col-sm-5"
                          control={
                            <Checkbox
                              checked={user.master}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const updatePermissions = (permissions) => {
                                  Object.keys(permissions).forEach((key) => {
                                    if (permissions[key]?.read !== undefined) {
                                      permissions[key].read = isChecked;
                                    }
                                    if (permissions[key]?.write !== undefined) {
                                      permissions[key].write = isChecked;
                                    }
                                  });
                                };
                                updatePermissions(user.permission);
                                user.master = isChecked;
                                setUser({ ...user });
                              }}
                              disabled={!masterUser.master}
                            />
                          }
                          label="Usuário Master"
                        />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}></h6>

                        <FormControlLabel
                          className="col-sm-5"
                          control={
                            <Checkbox
                              checked={Object.values(user.permission).every(permission => permission.read)}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const updateReadPermissions = (permissions) => {
                                  Object.keys(permissions).forEach((key) => {
                                    if (permissions[key]?.read !== undefined) {
                                      permissions[key].read = isChecked;
                                    }
                                  });
                                };
                                updateReadPermissions(user.permission);
                                setUser({ ...user });
                              }}
                              disabled={!masterUser.permission.users.write}
                            />
                          }
                          label="Ver Todos"
                        />

                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            onChange={(event) => {
                              user.permission.home.read = event.target.checked;
                              user.permission.users.read = event.target.checked;
                              user.permission.units.read = event.target.checked;
                              user.permission.cooperatives.read = event.target.checked;
                              user.permission.equipments.read = event.target.checked;
                              user.permission.maps.read = event.target.checked;
                              user.permission.charts.read = event.target.checked;
                              user.permission.equipmentsQL.read = event.target.checked;
                              user.permission.notifications.read = event.target.checked;
                              user.permission.userspassword.read = event.target.checked;
                              user.permission.general.read = event.target.checked;
                              user.permission.home.write = event.target.checked;
                              user.permission.users.write = event.target.checked;
                              user.permission.units.write = event.target.checked;
                              user.permission.cooperatives.write = event.target.checked;
                              user.permission.equipments.write = event.target.checked;
                              user.permission.maps.write = event.target.checked;
                              user.permission.charts.write = event.target.checked;
                              user.permission.support.read = event.target.checked;
                              user.permission.support.write = event.target.checked;
                              user.permission.equipmentsQL.write = event.target.checked;
                              user.permission.notifications.write = event.target.checked;
                              user.permission.userspassword.write = event.target.checked;
                              user.permission.general.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar Todos " />
                          
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Home</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.home.read}
                            onChange={(event) => {
                              user.permission.home.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.home.write}
                            onChange={(event) => {
                              user.permission.home.read = event.target.checked;
                              user.permission.home.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Usuários</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.users.read}
                            onChange={(event) => {
                              user.permission.users.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.users.write}
                            onChange={(event) => {
                              user.permission.users.read = event.target.checked;
                              user.permission.users.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Cooperativas</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.cooperatives.read}
                            onChange={(event) => {
                              user.permission.cooperatives.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.cooperatives.write}
                            onChange={(event) => {
                              user.permission.cooperatives.read = event.target.checked;
                              user.permission.cooperatives.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Unidades</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.units.read}
                            onChange={(event) => {
                              user.permission.units.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.units.write}
                            onChange={(event) => {
                              user.permission.units.read = event.target.checked;
                              user.permission.units.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Equipamentos</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.equipments.read}
                            onChange={(event) => {
                              user.permission.equipments.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.equipments.write}
                            onChange={(event) => {
                              user.permission.equipments.read = event.target.checked;
                              user.permission.equipments.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Mapa</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.maps.read}
                            onChange={(event) => {
                              user.permission.maps.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.maps.write}
                            onChange={(event) => {
                              user.permission.maps.read = event.target.checked;
                              user.permission.maps.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Curvas</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.charts.read}
                            onChange={(event) => {
                              user.permission.charts.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.charts.write}
                            onChange={(event) => {
                              user.permission.charts.read = event.target.checked;
                              user.permission.charts.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Suporte</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.support.read}
                            onChange={(event) => {
                              user.permission.support.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.support.write}
                            onChange={(event) => {
                              user.permission.support.read = event.target.checked;
                              user.permission.support.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Qualidade</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.equipmentsQL.read}
                            onChange={(event) => {
                              user.permission.equipmentsQL.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.equipmentsQL.write}
                            onChange={(event) => {
                              user.permission.equipmentsQL.read = event.target.checked;
                              user.permission.equipmentsQL.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Notificações</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.notifications.read}
                            onChange={(event) => {
                              user.permission.notifications.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.notifications.write}
                            onChange={(event) => {
                              user.permission.notifications.read = event.target.checked;
                              user.permission.notifications.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Gera senha</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.userspassword.read}
                            onChange={(event) => {
                              user.permission.userspassword.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.userspassword.write}
                            onChange={(event) => {
                              user.permission.userspassword.read = event.target.checked;
                              user.permission.userspassword.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>

                      <div className='col-sm-12 mt-0' style={{ "marginLeft": "10px" }}>
                        <h6 style={{ "marginTop": "15px", "marginBottom": '0px', "borderBottom": "solid 1px #80808061", "paddingBottom": "5px" }}>Configurações</h6>
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.general.read}
                            onChange={(event) => {
                              user.permission.general.read = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Ver" />
                        <FormControlLabel className='col-sm-5' control={
                          <Checkbox
                            checked={user.permission.general.write}
                            onChange={(event) => {
                              user.permission.general.read = event.target.checked;
                              user.permission.general.write = event.target.checked;
                              setUser({ ...user })
                            }}
                            disabled={!masterUser.permission.users.write}
                          />} label="Editar" />
                      </div>
                    </div>
                  </>
                }
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <CardActions>
        <Button
          disabled={permission.users.write === true ? false : true}
          className={classes.saveButton}
          onClick={saveUser}
          color="primary"
          variant="contained">
          Salvar
        </Button>
      </CardActions>
      <hr />
      <CardActions>
        <Button
          disabled={permission.users.read === true ? false : true}
          className={classes.saveButton}
          onClick={() => {
            setValueForm(true)
            setValueFormSenhas(false)
          }}
          color="primary"
          variant="contained">
          Abrir ultimas ativações
        </Button>
        <Button
          disabled={permission.users.read === true ? false : true}
          className={classes.saveButton}
          onClick={() => {
            setValueForm(false)
            setValueFormSenhas(true)
          }}
          color="primary"
          variant="contained">
          Exibir senhas geradas
        </Button>
      </CardActions>
    </Card>
  )
}

export default Form;
